import { Button } from "react-bootstrap";
import { BsDownload } from "react-icons/bs";
import swap from '../../images/swap.png'

function LiquiditySection() {
    return (
        <div className='contentContainer informationSection'>
            <div style={{maxWidth: '90%'}}>
                <div className='title'>
                    <span className='titleSecondary'>Provide Liquidity to <b>EcoPools</b></span>
                </div>
                <div className="sectionDescription">
                    <div className="descWrapper">Once the ECO token is launched ECO holders will be able to provide liquidity in pairs and get rewards for doing so. More info about liquidity pools will be disclosed once we announce the ECO token launch.</div>
                </div>
            </div>
            <Button className='wip' variant="primary" style={{width: 'fit-content', alignSelf: 'center'}}>
                <BsDownload size={20} />Deposit now
            </Button>
        </div>
    );
}

export default LiquiditySection
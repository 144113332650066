import { Button } from "react-bootstrap";
import { MdOutlineReadMore } from "react-icons/md";
import swap from '../../images/swap.png'
import { useState } from "react";

function AboutUsSection() {
    const [show, setShow] = useState(false);
    return (
        <div className='contentContainer informationSection'>
            <div style={{ maxWidth: '90%' }}>
                <div className='title'>
                    <span className='titleSecondary'><b>Our Journey</b> </span>
                </div>
                <div className="sectionDescription">
                    <div className="descWrapper" style={{textAlign: "justify", width: "900px"}}>EcoFlip was born from the shared ambition of two individuals, Miloš and Jakub, who simply wanted to build something meaningful together. During a particularly inspiring brainstorming session, they had a eureka moment: Why not establish a business that not only provides products and services but also contributes to environmental recovery? They were captivated by the idea, especially considering their long-standing involvement in the world of cryptocurrency. They saw the opportunity to create something innovative and impactful within the fast-paced, opportunity-rich landscape of the crypto universe.
                    </div>
                    { show && 
                    <> 
                        <div className="descWrapper" style={{textAlign: "justify", width: "900px"}}>Their focus then turned to developing ideas that would offer maximum value to users while also ensuring profitability for the company. They were on a quest for a triple win scenario - a win for them, a win for the users of their products and services, and a win for the environment. Their collective brainstorming led to the conception of some truly ambitious ideas. They envisioned a global multimillion-dollar company that would offer simple yet essential services to everyone in the crypto community and beyond. They imagined bridging the gap between the physical world and the crypto realm by offering environmentally focused services powered by blockchain technology. They dreamt of creating a revolutionary cryptocurrency that could contribute to environmental preservation simply through its use.
                            <br/><br/>Each of these grand ideas was the target of their dedicated efforts for a given period, with the hope that they would bear promising results. Yet, each idea was met with the harsh realities of execution. Despite the countless hours they invested in the formation of their company, the results they hoped for seemed perpetually out of reach.
                            <br/><br/>The ideation began in October 2021, and from that point on, we committed ourselves tirelessly to the work at hand. With abundant passion fuelling our efforts, we crafted our company logo, designed our website, and produced countless analyses of the environment we were about to enter. We conducted in-depth evaluations of our ambitious plans, including impact assessments, risk analyses, and identification of opportunities to seize. We used tools like SWOT, SLEPTE, McKinsey, 5S, and Porter's analyses, examined trends, and included graphs and figures. We made mathematical calculations for our revolutionary cryptocurrency and accomplished so much more. Driven by unwavering conviction and belief, we labored relentlessly to grow and realize our dream: to establish a profitable company that aids the environment.
                            <br/><br/>We participated in hackathons, attended Solana Breakpoint, submitted grant applications, and reached out to numerous potential investors, hoping for funding that seemed perpetually out of reach. We launched our swap, built our Discord community, negotiated collaborations with other companies, and yet our project didn't skyrocket as we'd hoped. Eventually, we grew tired of entrusting the fate of our project to others and decided to take the reins. We set out to create our first tangible product: the Roaring Tigers NFT collection. 
                            <br/><br/>With the launch of our NFT collection, we've arrived at the present moment. We've partnered with a gifted realistic artist who has not only helped us design the NFT website but has also brought to life the story of the tigers. With our input, they were able to create the NFT collection. We can't predict the success of this collection, but we do know that we're giving our all to fulfill the mission we've set out to achieve: to prosper and bring value to everyone who encounters our project in any way possible.
                            <br/><br/>We're prepared for the next chapter in the EcoFlip journey. The question remains...
                        </div> 
                        <div className="descWrapper" style={{textAlign: "center", width: "900px"}}><b>Are you ready to join us?</b>
                        </div>
                    </>
                    }
                </div>
            </div>
            <Button onClick={() => setShow(!show)} variant="primary" style={{width: 'fit-content', alignSelf: 'center'}}>
                <MdOutlineReadMore size={20} />Read {show ? "less" : "more"}
            </Button>
        </div>
    );
}

export default AboutUsSection
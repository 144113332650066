import './CustomNavbar.css';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import headerLogo from '../images/HeaderLogo.png'
import { Button } from 'react-bootstrap';
import { BiRocket } from 'react-icons/bi';
import { AiFillBell } from 'react-icons/ai'

function CustomNavbar() {
    const ele = document.getElementById('ipl-progress-indicator')
    if (ele) {
        // fade out
        ele.classList.add('available')
        ele.outerHTML = ''
    }
    return (
        <Navbar collapseOnSelect fixed='top' expand="lg" bg="dark" variant="dark">
            <Container>
                <Navbar.Brand href="#home"><img src={headerLogo} className="headerLogo-bg" alt="headerLogo-bg" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                    </Nav>
                    {/* <Nav>
                    <Nav.Link href="#contactSection" className='customnavlink'><AiFillBell />Subscribe to <b>EcoNews</b></Nav.Link>
                    </Nav> */}
                    <Nav>
                        <Nav.Link className='customnavlink accountButton' href='https://app.ecoflip.io' target="_blank" rel="noopener noreferrer">
                            <Button >
                                <BiRocket size={20} />Launch app
                            </Button>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default CustomNavbar;

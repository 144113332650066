import { Button } from "react-bootstrap";
import { AiOutlineClockCircle } from "react-icons/ai";
import staking from '../../images/staking.png'

function StakingSection() {
    return (
        <div className='contentContainer informationSection'>
            <div style={{ maxWidth: '90%' }}>
                <div className='title'>
                    <span className='titleSecondary'>Stake <b>ECO</b> tokens</span>
                </div>
                <div className="sectionDescription">
                    <div className="descWrapper">
                        Right after the ECO token launch staking will be implemented with DPO model (Don't Pull Out). DPO model allows for rewarding the long term investors more by calculating the amount of time staked.<br/><br/>
                        ECO stakers will also be periodically rewarded with Booster NFT's.<br/><br/>More info about DPO Staking & Booster NFT's will be disclosed with the ECO token launch.</div>
                </div>
                <div className="sectionDescription">
                    <img src={staking} className="wertIntegrationImage"/>
                </div>
            </div>
            <Button className='wip' variant="primary" style={{width: 'fit-content', alignSelf: 'center'}}>
                <AiOutlineClockCircle size={20} />Stake now
            </Button>
        </div>
    );
}

export default StakingSection
import { Button } from "react-bootstrap";
import { AiOutlineSwap, AiOutlineGlobal } from "react-icons/ai"
import { GiMagnifyingGlass, GiCoins } from "react-icons/gi"
import { MdOutlineReadMore } from "react-icons/md";

function DefiSection() {
    return (
        <div className='contentContainer defiSection'>
            <div style={{ maxWidth: '90%' }}>
                <div className='title'>
                    <span className='titleSecondary'>Utilize our <b>DeFi tools</b></span>
                </div>
                <div className="sectionDescription">
                    <div className="descWrapper">Leverage the DeFi tools offered by EcoFlip. Experience the complete functionality of DeFi while simultaneously contributing to transparent environmental organizations.</div>
                </div>
                <div className="cardContainer">
                    <div className="defiCard orangeBackground">
                        <div className="defiCardTitle">
                            EcoSwap
                        </div>
                        <div className="defiCardDescription">
                        Exchange your tokens and allocate a portion of the swap fee to influential environmental endeavors.
                        </div>
                        <div className="defiCardButtonGroup">
                            <Button href="https://app.ecoflip.io" className="defiCardButton secondary" variant="secondary"><AiOutlineSwap size={20}/>EcoSwap</Button>
                            {/* <Button className="defiCardButton" variant="secondary"><GiMagnifyingGlass size={20}/> Learn more</Button> */}
                        </div>
                    </div>
                    {/* <div className="defiCard orangeBackground">
                        <div className="defiCardTitle">
                            Roaring Tigers
                        </div>
                        <div className="defiCardDescription">
                        Exchange your tokens and allocate a portion of the swap fee to influential environmental endeavors.
                        </div>
                        <div className="defiCardButtonGroup">
                            <Button href="https://tigers.ecoflip.io" target="_blank" rel="noopener noreferrer" className="button" variant="primary" style={{border: "1px solid #B7FFAC"}}><AiOutlineGlobal size={20}/>Website</Button>
                            <Button href="#nftSection" className="defiCardButton secondary" variant="secondary"><MdOutlineReadMore size={20}/>Learn more</Button>
                            <Button className="defiCardButton" variant="secondary"><GiMagnifyingGlass size={20}/> Learn more</Button>
                        </div>
                    </div> */}
                </div>
                <div className="moreToolsSoon" style={{opacity: '0.5'}}>More tools will be announced in the future</div>
            </div>
        </div>
    );
}

export default DefiSection
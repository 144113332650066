import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './components/Sections/Sections.css';
import CustomNavbar from './components/CustomNavbar';
import MainPageCard from './components/MainPageCard';
import {FiChevronsDown} from 'react-icons/fi'
import { 
  DefiSection,
  NFTSection,
  FlipSection,
  TokenSection,
  ContactSection,
  CreditCardSection,
  StakingSection,
  SwapSection,
  LiquiditySection,
  PoweredBySection,
  SubscribeSection,
  FooterSection,
  JoinDaoSection,
  RoaringTigers,
  AboutUsSection
} from './components/Sections';
import MainPageTextCard from './components/MainPageTextCard';
import React from 'react';
import YoutubeEmbed from './components/YoutubeEmbed';

function App() {
  return (
    <div className="App">
      {/* <div id='loadingScreen' className='loadingScreen'><img src={Logo} className="logo-loading" alt="earth-bg" /></div> */}
      <CustomNavbar />
      <div className='content'>
        <div className='contentContainer forestBackground'>
          <div className="background" />
          <MainPageTextCard />
          {/* <img src={grass} className="grass-bg" alt="grass-bg" /> */}
          <div className='scrollDown'>
            Learn more<br />
            <FiChevronsDown size={30} />
          </div>
        </div>
        <YoutubeEmbed embedId='2loSSvbM4r0'/>
        <DefiSection />
        {/* <TokenSection /> */}
        <AboutUsSection/>
        <CreditCardSection />
        <SwapSection/>
        {/* <LiquiditySection/> */}
        {/* <StakingSection/> */}
        {/* <FlipSection /> */}
        <JoinDaoSection />
        <NFTSection />
        <RoaringTigers/>
        {/* <SubscribeSection/> */}
        <PoweredBySection/>
        <ContactSection />
        <FooterSection />
      </div>
    </div>
  );
}

export default App;

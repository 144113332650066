import { Button } from "react-bootstrap";
import { RiCoinLine } from "react-icons/ri";
import solana from "../../images/solanalogo.png"
import jupiter from "../../images/jupiter.png"
import wert from "../../images/Wert.png"
import koia from "../../images/koia.png"
import azure from "../../images/azure.png"

function PoweredBySection() {
    return(
        <div className='contentContainer subscribeSection'>
            <div style={{maxWidth: '90%'}}>
                <div className='title'>
                    <span className='titleSecondary' style={{fontSize: '1em'}}>Powered by</span><br/>
                </div>
                <div className="sectionDescription">
                    <div className="partnerLogos descWrapper">
                        <img className="partnerLogo" style={{height: '30px'}} src={solana}/>
                        <img className="partnerLogo" style={{height: '50px'}} src={jupiter}/>
                        {/* <img className="partnerLogo" style={{height: '30px'}} src={wert}/> */}
                        <img className="partnerLogo" style={{height: '50px'}} src={koia}/>
                        <img className="partnerLogo" style={{height: '45px'}} src={azure}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PoweredBySection 
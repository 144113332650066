import './MainPageTextCard.css';
import { SiLinktree } from 'react-icons/si';
import {GiTigerHead} from 'react-icons/gi'
import { BiRocket } from 'react-icons/bi'
import Button from 'react-bootstrap/Button';
import YoutubeEmbed from './YoutubeEmbed';

function MainPageTextCard() {
    return (
        <div className='mainPageTextCard'>
            <div className='title'>
                <span className='titleSecondary'>Discover <b>EcoFlip</b></span><br />
                <span className='titlePrimary'>the environmental DAO</span>
            </div>
            <div className='description' style={{fontSize: '1.25em'}}>
                <b>EcoFlip</b> aspires to be the world's leading and largest <b>environmental DAO</b>. Connect with us on our social media channels and be a part of the premier <b>eco-conscious community</b>.<br/><br/>
                A significant portion of the fees generated through the use of the EcoFlip platform will be directly allocated to carefully selected organizations and initiatives dedicated to preserving our precious planet Earth.
            </div>
            <div className='buttons'>
                <Button href='https://app.ecoflip.io' target="_blank" rel="noopener noreferrer" variant="primary">
                    <BiRocket size={20} />Launch app
                </Button>
                <Button href='https://linktr.ee/ecoflip' target="_blank" rel="noopener noreferrer" className='' variant="secondary">
                    <SiLinktree size={20} />Linktr.ee/ecoflip
                </Button>
            </div>
            <div className='buttons2'>
                <Button href='https://tigers.ecoflip.io' target="_blank" rel="noopener noreferrer" variant="primary" style={{backgroundColor: "purple", borderColor: "purple"}}>
                    <GiTigerHead size={20} />Roaring Tigers
                </Button>
                <Button href='https://mint.ecoflip.io' target="_blank" rel="noopener noreferrer" variant="primary" style={{backgroundColor: "purple", borderColor: "purple"}}>
                    <GiTigerHead size={20} />Mint
                </Button>
            </div>
        </div>
    );
}

export default MainPageTextCard;
import { Button } from "react-bootstrap";
import { AiOutlineGlobal } from "react-icons/ai";
import wertIntegration from '../../images/dopici.png'
/*href='https://app.ecoflip.io/buy'*/
function CreditCardSection() {
    return (
        <div className='contentContainer informationSection' style={{marginBottom: 0, paddingBottom: 0}}>
            <div style={{ maxWidth: '90%' }}>
                <div className='title'>
                    <span className='titleSecondary'><b>EcoFlip DAO</b>  voting</span>
                </div>
                <div className="sectionDescription">
                    <div className="descWrapper">We are thrilled to announce the upcoming addition of the <b>Ecoflip DAO Voting</b> feature to our platform. This innovative, work-in-progress feature empowers our <b>vote token holders</b> to actively participate in the decision-making process for fund distribution.</div>
                </div>
                <div className="sectionDescription">
                    <img src={wertIntegration} className="wertIntegrationImage"/>
                </div>  
                <div className="sectionDescription">
                    <div className="descWrapper">By leveraging their <b>voting power</b>, token holders will be able to directly donate a proportional amount from the treasury to the projects they vote for during each distribution of funds. Our aim is to foster a more sustainable and responsible future by giving our community the ability to influence and support <b>eco-friendly projects</b>.</div>
                </div>
                <div className="sectionDescription">
                    <div className="descWrapper"><b>Ecoflip DAO Voting</b> is currently being developed for the <b>Solana devnet</b>, and we are eager to make it available for testing soon. We believe that the Solana ecosystem, with its robust architecture and lightning-fast transaction capabilities, is the perfect environment for hosting our unique voting system.</div>
                </div>
                <div className="sectionDescription">
                    <div className="descWrapper">Stay tuned for updates on the launch of this exciting new feature, and together, let's make a lasting positive impact on our planet.</div>
                </div>
            </div>
        </div>
    );
}

export default CreditCardSection
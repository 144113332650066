import { BsMedium } from 'react-icons/bs';
import {FaDiscord, FaTwitter} from 'react-icons/fa'

function FooterSection() {
    return (
        <div className='contentContainer footerSection'>
            <div style={{display: 'flex', gap: '2em', marginBottom: '1em'}}>
                <a href='https://discord.gg/n2KUyN8KCd' target="_blank" rel="noopener noreferrer" className='footerIcon'><FaDiscord size={30}/></a>
                <a href='https://twitter.com/ecoflip_io' target="_blank" rel="noopener noreferrer" className='footerIcon'><FaTwitter size={30}/></a>
                <a href='https://medium.com/@ecoflip.io/about' target="_blank" rel="noopener noreferrer" className='footerIcon'><BsMedium size={30}/></a>
            </div>
            <div style={{fontSize: '0.75em', opacity: '0.5'}} className="footerCardTitle">All rights reserved to EcoFlip s.r.o.</div>
        </div>
    );
}

export default FooterSection
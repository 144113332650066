import { ContactForm } from "./ContactForm";


function ContactSection() {
    return (
        <div className='contentContainer contactSection'>
            <div style={{maxWidth: '90%'}}>
                <div className='title'>
                    <span className='titleSecondary'>Any <b>questions</b>?</span><br />
                </div>
                <div className="sectionDescription">
                    <div className="descWrapper">
                        If you have any questions please use our <a style={{color: "inherit", fontWeight:"bold"}} href='https://discord.gg/n2KUyN8KCd' target="_blank" rel="noopener noreferrer" >Discord</a> channel. <br/>You can also use the form bellow.
                    </div>
                    <ContactForm/>
                </div>
            </div>
        </div>
    );
}

export default ContactSection
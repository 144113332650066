import { Button } from "react-bootstrap";
import { AiOutlineGlobal } from "react-icons/ai";

function NFTSection() {
    return (
        <div id="nftSection" className='contentContainer informationSection nft nftBackground'>
            <div style={{maxWidth: '90%'}}>
                <div className='title'>
                    <span className='titleSecondary'>Discover <b>Endangered Species NFTs</b></span><br />
                    <span className='titlePrimary'></span>
                </div>
                <div className="sectionDescription">
                    <div className="descWrapper" style={{textAlign: 'center'}}>  
                    <b>EcoFlip</b> has partnered with talented artists to create unique NFT collections that highlight endangered species. Our first collection, <b>Roaring Tigers</b>, showcases a captivating assortment of fantasy tigers.
                    </div>
                </div>
                <div className="sectionDescription">
                    <div className="descWrapper" style={{textAlign: 'center'}}>  
                    With each <b>Roaring Tigers NFT</b> minted, <b>50%</b> of the proceeds will be donated directly to organizations dedicated to conserving tiger populations. Additionally, royalties generated from secondary sales will be allocated to the organizations, EcoFlip Team and towards the giveaway rewards redistributed to the NFT stakers through raffles.
                    </div>
                </div>
                <div className="sectionDescription">
                    <div className="descWrapper" style={{textAlign: 'center', paddingBottom: "0"}}>  
                    Join us in our mission to raise awareness and support endangered species while also embracing the world of digital art. Don't miss this opportunity to collect these extraordinary Roaring Tigers NFTs and make a tangible impact on our planet's future.
                    </div>
                </div>
                <div className="sectionDescription" style={{marginTop: "2em"}}>
                    <Button href="https://tigers.ecoflip.io" target="_blank" rel="noopener noreferrer" className="defiCardButton secondary" variant="secondary"><AiOutlineGlobal size={20}/>Roaring Tigers</Button>
                </div>
                

                {/* <div style={{textAlign: 'center'}}>  
                    <div className="nftUtilityCards">
                        <div className="nftCard" style={{textAlign: 'center'}}>
                        <h2><b>Get access to events</b></h2>Get access to the events hosted by EcoFlip
                        </div>
                        <div className="nftCard" style={{textAlign: 'center'}}>
                        <h2><b>Get access to private group</b></h2>Connect with a community of holders with the same mindset and network with them
                        </div>
                        <div className="nftCard" style={{textAlign: 'center'}}>
                        <h2><b>Earn part of the fees</b></h2>Staked NFT's earn you a part of the fees from the EcoFlip fee generating mechanisms such as part of the coinflip fee and part of their royalties.
                        </div>
                        <div className="nftCard" style={{textAlign: 'center'}}>
                        <h2><b>Be a proud supporter</b></h2>By having those NFT's you show your commitment to the project all while taking home some of our profits. Isn't that useful?
                        </div>
                        <div className="nftCard" style={{textAlign: 'center'}}>
                        <h2><b>Own a real tree</b></h2>EcoFlip will plant a real life tree for every Oak & Sequoia NFT minted.
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default NFTSection
import { Button } from "react-bootstrap";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { RiCoinLine } from "react-icons/ri";

function JoinDaoSection() {
    return(
        <div className='contentContainer informationSection'>
            
            <div style={{maxWidth: '90%'}}>
                <div className="title">
                    <span className='titleSecondary'>Get Listed on <b>EcoFlip DAO</b></span>
                </div>
                <div className="sectionDescription">
                    <div className="descWrapper">
                    If you are affiliated with an environmental project and wish to be included in the EcoFlip DAO, please use the button below to complete the application form. This feature will be integrated into the DAO in the future.
                    </div>
                    <Button href="https://forms.gle/X4tXhtkPL67gwAN56" target="_blank" rel="noopener noreferrer" variant="primary"><AiOutlineUnorderedList size={20}/>Apply for listing</Button>
                </div>
            </div>
        </div>
    );
}

export default JoinDaoSection 
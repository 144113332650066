function RoaringTigers() {
    return (
        <div style={{backgroundColor: "#A8B9D7", width: "100vw", display: "flex", justifyContent: "center"}}>
            <div className='roaringTigers'>
            
            </div>
        </div>

    );
}

export default RoaringTigers
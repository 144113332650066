import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { FloatingLabel } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { RiLoader5Line, RiSendPlaneFill } from 'react-icons/ri';
import { FormLabelOwnProps } from 'react-bootstrap/esm/FormLabel';

export const ContactForm = () => {
    const form = useRef();
    const [sendState, setSendState] = useState(0);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const sendEmail = (e: any) => {
        e.preventDefault();
        setSendState(1);
        setButtonDisabled(true);
        //@ts-ignore
        emailjs.sendForm('service_aohwqun', 'template_llxkbny', form.current, 'xr4lUtShopomf4OHe')
            .then((result) => {
                setSendState(2);
                const form2 = document.getElementById('form');
                //@ts-expect-error
                form2.reset();
                setButtonDisabled(false);
                console.log(result.text);
            }, (error) => {
                setSendState(3);
                setButtonDisabled(false);
                console.log(error.text);
            });
    };

    return (

        <form id="form" style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
            //@ts-expect-error
        }} onSubmit={sendEmail} ref={form}>
            <Form.Group controlId="formBasicEmail" >
                <FloatingLabel
                    controlId="floatingInputName"
                    label="Name"
                    className="mb-3"
                >
                    <Form.Control type="text" name="from_name" className='emailInput contactEmailInput' placeholder='Name' style={{ color: 'var(--secondary-green-color)' }}/>
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInputEmail"
                    label="Email address"
                    className="mb-3"
                >
                    <Form.Control type="email" name="from_email" className='emailInput contactEmailInput' placeholder='Email address' style={{ color: 'var(--secondary-green-color)' }} />
                </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
                <FloatingLabel
                    label="Message"
                    className="mb-3"
                >
                    <Form.Control as="textarea" name="message" className='textArea' placeholder='Message' style={{ height: '250px', paddingLeft: '2em', paddingRight: '2em' }} />
                </FloatingLabel>
            </Form.Group>
            <Button disabled={buttonDisabled} style={{ alignSelf: 'center' }} variant="secondary" type="submit">
                <RiSendPlaneFill size={20} />Send email
            </Button>
            {
                sendState == 1 &&
                <div style={{marginTop: '1em', marginBottom: '1em'}}><RiLoader5Line size={30} className="spinnerIcon"/></div>
            }
            {
                sendState == 2 &&
                <div style={{marginTop: '1em', marginBottom: '1em'}}>Successfully sent!</div>
            }
            {
                sendState == 3 &&
                <div style={{marginTop: '1em', marginBottom: '1em', color: 'red'}}>Something went wrong please contact us directly through: contact@ecoflip.io</div>
            }
        </form>
    );
};
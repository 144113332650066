function PartnersSection() {
    return (
        <div className='contentContainer'>
            <div>
                <div className='title'>
                    <span className='titleSecondary'>Learn about our</span><br />
                    <span className='titlePrimary'>Partners</span>
                </div>
                <div className="sectionDescription">
                    <div>
                        We are choosing our partners wisely. The collaborations need to be meaningful and help us have the desired impact. With that in mind, our partners need to be trusted, transparent companies with good intentions so you and our community can be sure those collaborations can be trusted.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PartnersSection
import { Button } from "react-bootstrap";
import { AiOutlineSwap } from "react-icons/ai";
import swap from '../../images/swap.png'

function SwapSection() {
    return (
        <div className='contentContainer informationSection'>
            <div style={{ maxWidth: '90%' }}>
                <div className='title'>
                    <span className='titleSecondary'>Swap with <b>EcoSwap</b></span>
                </div>
                <div className="sectionDescription">
                    <div className="descWrapper">Exchange your preferred tokens while simultaneously supporting the environment.<br/><br/>
                    By utilizing EcoSwap for your everyday token exchanges, you're aiding environmental initiatives and the growth of the EcoFlip DAO.<br/><br/>
                    Each swap incurs a 0.20% platform fee, which is directly channeled to environmental organizations.
                    </div>
                </div>
                <div className="sectionDescription">
                    <img src={swap} className="wertIntegrationImage"/>
                </div>
            </div>
            <Button href='https://app.ecoflip.io' target="_blank" rel="noopener noreferrer" variant="primary" style={{width: 'fit-content', alignSelf: 'center'}}>
                <AiOutlineSwap size={20} />Swap now
            </Button>
        </div>
    );
}

export default SwapSection